import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const OmegaDeVille = () => {
  const data = useStaticQuery(
    graphql`
      query queryOmegaDeVille {
        products: allStrapiProduct(
          filter: { brand: { eq: "Omega" }, model: { eq: "DeVille" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Omega DeVille Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/omega/de-ville/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Omega DeVille Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/omega/de-ville/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-omega-deville-header.png"
                alt="Pre-Owned Certified Used Omega De Ville Watches Header"
                aria-label="Used Omega De Ville Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Omega Constellation</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE OMEGA DE VILLE WATCHES AT
              GRAY AND SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED OMEGA DE VILLE WATCHES WITH
              GRAY AND SONS JEWELERS
            </h3>
            <p>
              <br />
              <br />
              All around the nation, Gray and Sons Jewelers is recognized as the top #1 buyer,
              seller, and repair center for Omega De Ville watches. In our store located in South
              Florida, we have on-site Omega watch specialists and jewelry experts to cater to your
              every need. Open Monday through Saturday from 10 AM to 5 PM, our brick and mortar
              store has been successfully catering to all luxury watch and jewelry needs of clients
              around the country for over 42 years. If ever in South Florida, come visit our store
              in the city of Bal Harbour near Sunny Isles, Aventura, Hollywood, Fort Lauderdale,
              Surfside, Miami Beach, Coconut Grove, Coral Gables, Miami, and Miami Beach. The
              clients don’t have to physically be in the store to do business with us; on our site,{' '}
              <a href="/">www.grayandsons.com </a> clients can inquire about Omega De Ville and many
              more.
              <br />
              <br />
              <b>About Pre-Owned Omega De Ville Watches at Gray and Sons</b>
              <br />
              <br />
              The Omega De Ville line is a watch collection that introduced elegance to precision.
              Modern Omega De Ville watches now contain pristine movements with co-axial escapements
              and chronometer certifications while still identifying itself as a dress watch.
              However, the De Ville collection hasn’t always been its own line.
              <br />
              <br />
              When Omega first introduced the De Ville, it was a variation of the Seamaster
              collection in 1960, being one of the finest dress watches of its time. As demand
              continued to grow for diving watches, Omega gave both collections the attention
              deserved and separated the ‘Seamaster’ and ‘De Ville’ collections in 1967.
              <br />
              <br />
              It only took the Omega De Ville collection 10 years to be nominated for and actually
              receive the grand prize from the Committee of European Excellence. The De Ville models
              had such fine qualities and were so alluring while executing pristine timekeeping that
              the “Triumph of European Excellence” could be awarded to none other.
              <br />
              <br />
              Innovation was never put to a halt at Omega. Over the next decades, the Omega De Ville
              line gained immense popularity especially in 1999 when Omega introduced the first De
              Ville carrying a Co-Axial movement. And the innovation continued; in 2007 collection
              raved over the impeccable attention to detail and craftsmanship that went into Omega
              creating the De Ville Hour which came equipped with the 8500 Co-Axial escapements.
              Sub-collections in the Omega De Ville collection bring different looks and
              complications to the line; some of these are Ladymatic, Tresor, Tourbillon, Hour
              Vision, and Prestige.
              <br />
              <br />
              Popular Pre-Owned Omega De Ville References are: Reference 145.018 Reference 111067
              Reference 244946 Reference 46338000 Reference 4877.60.37 Reference 53110106 Reference
              42425276055002 Reference 8196 Reference 166.094 Reference 116.1108 Reference 8340
              Reference 5931.81.23 Reference 48415031 Reference 422.58.35.50.13.001
              <br />
              <br />
              <b>Buy Pre-Owned Omega De Ville Watches</b>
              <br />
              <br />
              Many clients buy used Omega De Ville watches from Gray and Sons Jewelers. We have the
              best inventory of pre-owned and second-hand Omega watches. Here at Gray and Sons, we
              assure clients are buying certified authentic Omega watches. We also provide a
              12-month warranty to all our in-stock used Omega watches. Live representatives,
              Viktoria and Rich, are ready to make a deal with you right now on our live chat.
              Access our decision-makers via our site’s live chat to buy, sell, trade, or repair
              used and pre-owned Omega watches today! Click <a href="/chat-with-rich">HERE </a>
              to reach out to our expert Omega De Ville watch Specialist.
              <br />
              <br />
              Many aren't aware of the perks that come with purchasing a used and pre-owned Omega De
              Ville watch. Buying a second-hand Omega watch can mean getting a better deal on your
              watch than the retail market price, finding rare hard-to-find pieces, and even getting
              a hold of discontinued watch models. Whether vintage De Ville or modern De Ville,
              Omega watches are one of the finest high-end watch manufacturers of all time.
              <br />
              <br />
              <b>Sell Pre-Owned Omega De Ville Watches</b>
              <br />
              <br />
              Many people ask “Where can I sell my used watch near me?” or “How much can I get for
              my used watch?”. Luckily for those clients, Gray and Sons has a buying department
              in-store and online named Sell Us Your Jewelry which will buy your pre-owned watch for
              cash today. We pay top dollar for all used and pre-owned luxury watches in little to
              no wait time. Anyone looking to trade-in or repair their own Omega watch should visit
              Gray and Sons in-person or online. Visit{' '}
              <a href="https://sellusyourjewelry.com/">wwwsellusyourjewelry.com </a>
              to sell omega watch online for cash. We offer certified authentic and certfied
              pre-owned watches.
              <br />
              <br />
              <b>Service Pre-Owned Omega De Ville Watches</b>
              <br />
              <br />
              All around the world, Omega watches are constantly in demand for being one of the top
              watch brands in the luxury market. Anyone who owns an Omega watch or want to buy an
              Omega watch should only trust their timepiece in the hands of the best. Here at Gray
              and Sons Jewelers we have six master-trained watchmakers with over 150 years of
              combined experience who service every single watch in our inventory. We provide repair
              services to the public as well as provide a 1 year warranty for all Omega De Ville
              watches sold at Gray and Sons. In-house we offer the following services: cleaning,
              polishing, overhaul and lubrication of the movement, battery changing, waterproofing,
              and much more! Come visit our store to repair my Omega De Ville watch.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/omega/omega-de-ville/">
                <button>SHOP OMEGA WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK OMEGA WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default OmegaDeVille
